import Vue from 'vue'
const state = {
	customer: null,
	customerId: null,
	contractId: null,
	isInAfterSaleRoom: false,
	enableLessee: false,
	menuOption: 'generate',
	loadingState: false,
	savingState: false,
	showSuccessView: false,
	showErrorView: false,
	showAutomaticScheduling: false,
	showReSchedule: false,
	showRecoverPassword: false,
	showLesseeWidget: false,
	requestResponse: null,
	requestId: null,
	daysAndHours: null,
	daysAndHoursWithIds: null,
	availableHours: null,
	hasAutomaticScheduling: false,
	automaticSchedulingCode: null,
	hollidaysChile: null,
	clientRecoverPassEmail: null,
	supportEmail: null,
	logInImage: null,
}
const mutations = {
	setCustomer: (state, data) => (state.customer = data),
	setCustomerId: (state, data) => (state.customerId = data),
	setContractId: (state, data) => (state.contractId = data),
	setIsInAfterSaleRoom: (state, data) => (state.isInAfterSaleRoom = data),
	setEnableLessee: (state, data) => (state.enableLessee = data),
	setMenuOption: (state, data) => (state.menuOption = data),
	setLoadingState: (state, data) => (state.loadingState = data),
	setSavingState: (state, data) => (state.savingState = data),
	setShowSuccessView: (state, data) => (state.showSuccessView = data),
	setShowErrorView: (state, data) => (state.showErrorView = data),
	setShowAutomaticScheduling: (state, data) => (state.showAutomaticScheduling = data),
	setRequestResponse: (state, data) => (state.requestResponse = data),
	setRequestId: (state, data) => (state.requestId = data),
	setDaysAndHours: (state, data) => (state.daysAndHours = data),
	setDaysAndHoursWithIds: (state, data) => (state.daysAndHoursWithIds = data),
	setAvailableHours: (state, data) => (state.availableHours = data),
	setHasAutomaticScheduling: (state, data) => (state.hasAutomaticScheduling = data),
	setAutomaticSchedulingCode: (state, data) => (state.automaticSchedulingCode = data),
	setShowReSchedule: (state, data) => (state.showReSchedule = data),
	setShowRecoverPassword: (state, data) => (state.showRecoverPassword = data),
	setShowLesseeWidget: (state, data) => (state.showLesseeWidget = data),
	setHollidaysChile: (state, data) => (state.hollidaysChile = data),
	setClientRecoverPassEmail: (state, data) => (state.clientRecoverPassEmail = data),
	setSupportEmail: (state, data) => (state.supportEmail = data),
	setLogInImage: (state, data) => (state.logInImage = data),
}
const actions = {
	async createRequest( { commit, rootGetters, } ){
		commit('general/setSavingState', true, { root: true })
		const project_info = rootGetters["assets/getSelectedProject"]
		const asset_info = rootGetters["assets/getSelectedAsset"]
		const request_elements = rootGetters["elements/getAddedRequirements"]
		const current_date = new Date()
		const date = current_date.getFullYear()+'-'+(current_date.getMonth()+1)+'-'+current_date.getDate()+' '+current_date.getHours()+':'+current_date.getMinutes()+':'+current_date.getSeconds()
		await Vue.axios
		.post('frontal_requests/create_request_from_widget', {
				project_name: project_info.project_name,
				project_id: project_info.project_id,
				asset_id: asset_info.asset_id,
				asset_type: asset_info.asset_type,
				asset_number: asset_info.asset_number,
				date: date,
				request_elements
			},
		)
		.then(response => {
			if(!response.data) {
				commit('general/setSavingState', false, { root: true })
				commit('setShowErrorView', true)
				console.log('Error')
				return
			}
			commit('general/setSavingState', false, { root: true })
			commit('setRequestResponse', response.data.folio)
			commit('setHasAutomaticScheduling', response.data.automatic_scheduling)
			commit('setAutomaticSchedulingCode', response.data.code)
			commit('setRequestId', response.data.request_id)
			commit('setShowSuccessView', true)
		})
		.catch(error => {
			console.log(error)
			commit('general/setSavingState', false, { root: true })
			commit('setShowErrorView', true)
		})
	},
	async updateClientInfo( { commit }, user ){
		commit('general/setSavingState', true, { root: true })
		await Vue.axios
		.post('frontal_requests/update_client_from_widget', {
				user
			},
		)
		.then(response => {
			commit('general/setSavingState', false, { root: true })
			if(!response.data) {
				console.log('Error')
				commit('setShowErrorView', true)
				return
			}
			commit('setShowSuccessView', true)
		})
		.catch(error => {
			console.log(error)
			commit('general/setSavingState', false, { root: true })
			commit('setShowErrorView', true)
		})
	},
	async recoverPassword( { commit }, {rut, customer_id, url} ){
		commit('general/setSavingState', true, { root: true })
		await Vue.axios
		.get(`frontal_requests/recover_password?rut=${rut}&customer_id=${customer_id}&url=${url}`)
		.then(response => {
			commit('general/setSavingState', false, { root: true })
			if(!response.data || response.data.success == false) {
				commit('setShowErrorView', true)
				console.log('Error')
				return
			}
			commit('setShowSuccessView', true)
			commit('setClientRecoverPassEmail', response.data.client_email)
			commit('setSupportEmail', response.data.support_email)
		})
		.catch(error => {
			console.log(error)
			commit('general/setSavingState', false, { root: true })
			commit('setShowErrorView', true)
		})
	},
	async useCustomLoginImage( { commit }, customerId ){
		await Vue.axios
		.get(`frontal_requests/login_image?customer_id=${customerId}`)
		.then(response => {
			if(!response.data || response.data.success == false) {
				console.log('Error')
				return
			}
			console.log(response.data.login_image)
			commit("setLogInImage", response.data.login_image)
		})
		.catch(error => {
			console.log(error)
			commit("setLogInImage", "")
		})
	},
	async automaticScheduling( { commit }, {request_id, starting_date, date, type} ){
		commit('general/setSavingState', true, { root: true })
		await Vue.axios
		.post('frontal_requests/automatic_scheduling', {
			request_id: request_id,
			starting_date: starting_date,
			date: date,
			type: type,
		},
		)
		.then(response => {
			commit('general/setSavingState', false, { root: true })
			if(!response.data) {
				commit('general/setSavingState', false, { root: true })
				console.log('Error')
				return
			}
			commit('setShowSuccessView', true)
		})
		.catch(error => {
			console.log(error)
			commit('general/setSavingState', false, { root: true })
		})
	},
	async getAvailableHoursPerDay( { commit }, {code, date, request_id} ){
		commit('general/setSavingState', true, { root: true })
		await Vue.axios
		.get(`frontal_requests/get_scheduling?code=${encodeURIComponent(code)}&date=${date}&request_id=${request_id}`)
		.then(response => {
			commit('general/setSavingState', false, { root: true })
			if(!response.data) {
				commit('general/setSavingState', false, { root: true })
				console.log('Error getting hours per day')
				return
			}
			commit('setAvailableHours', response.data)
		})
		.catch(error => {
			console.log(error)
			commit('general/setSavingState', false, { root: true })
		})
	},
	async getDaysAndHoursToReSchedule( { commit } ){
		await Vue.axios
		.get('frontal_requests/get_days_and_hours')
		.then(response => {
			if(!response.data) {
				console.log('Error getting hours per day')
				return
			}
			commit('setDaysAndHours', response.data.days_and_hours)
			commit('setDaysAndHoursWithIds', response.data.days_and_hours_with_ids)
		})
		.catch(error => {
			console.log(error)
		})
	},
	async getYearlyHollidaysChile( { commit } ){
		await Vue.axios
		.get('frontal_requests/get_hollidays_of_chile')
		.then(response => {
			if(!response.data) {
				console.log('Error getting yearly hollidays')
				return
			}
			commit('setHollidaysChile', response.data.hollidays)
		})
		.catch(error => {
			console.log(error)
		})
	},
	async generateSecondaryUser( { commit }, user ){
		await Vue.axios
		.post('frontal_requests/create_secondary_user', {
				user
			},
		)
		.then(response => {
			commit('general/setSavingState', false, { root: true })
			if(!response.data) {
				console.log('Error')
				commit('setShowErrorView', true)
				return
			}
			commit('setShowSuccessView', true)
		})
		.catch(error => {
			console.log(error)
			commit('general/setSavingState', false, { root: true })
			commit('setShowErrorView', true)
		})
	},
	resetGeneralOnLogOut( { commit } ){
		commit('setMenuOption', 'generate')
		commit('setLoadingState', false)
		commit('setSavingState', false)
		commit('setShowSuccessView', false)
		commit('setShowErrorView', false)
		commit('setShowAutomaticScheduling', false)
		commit('setRequestResponse', null)
		commit('setRequestId', null)
		commit('setDaysAndHours', null)
		commit('setDaysAndHoursWithIds', null)
		commit('setAvailableHours', null)
		commit('setHasAutomaticScheduling', false)
	},
}
const getters = {
	getCustomer: state => state.customer,
	getCustomerId: state => state.customerId,
	getContractId: state => state.contractId,
	getIsInAfterSaleRoom: state => state.isInAfterSaleRoom,
	getEnableLessee: state => state.enableLessee,
	getMenuOption: state => state.menuOption,
	getLoadingState: state => state.loadingState,
	getSavingState: state => state.savingState,
	getShowSuccessView: state => state.showSuccessView,
	getShowErrorView: state => state.showErrorView,
	getRequestResponse: state => state.requestResponse,
	getHasAutomaticScheduling: state => state.hasAutomaticScheduling,
	getautomaticSchedulingCode: state => state.automaticSchedulingCode,
	getShowAutomaticScheduling: state => state.showAutomaticScheduling,
	getShowReSchedule: state => state.showReSchedule,
	getShowRecoverPassword: state => state.showRecoverPassword,
	getShowLesseeWidget: state => state.showLesseeWidget,
	getRequestId: state => state.requestId,
	getDaysAndHours: state => state.daysAndHours,
	getDaysAndHoursWithIds: state => (dayNumber, hour) => {
		let hour_id = state.daysAndHoursWithIds[dayNumber + 1].filter(function (day)
			{
				return day.name == hour
			}
		)
		return hour_id[0].id
	},
	getAvailableHours: state => state.availableHours,
	getHollidaysChile: state => state.hollidaysChile,
	getClientRecoverPassEmail: state => state.clientRecoverPassEmail,
	getSupportEmail: state => state.supportEmail,
	getLogInImage: state => state.logInImage,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
