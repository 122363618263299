<template>
	<transition 
		name="mobysuite-aftersale-client-modal-fade"
	>
		<div 
			class="mobysuite-aftersale-client-modal-backdrop"
		>
			<div
				class="mobysuite-aftersale-client-modal"
				role="dialog"
				aria-labelledby="mobysuite-aftersale-client-modalTitle"
				aria-describedby="mobysuite-aftersale-client-modalDescription"
			>
				<header 
					class="mobysuite-aftersale-client-modal-header"
					id="mobysuite-aftersale-client-modalTitle"
				>
					<button
						type="button"
						class="mobysuite-aftersale-client-btn-close"
						@click="close"
						aria-label="Close mobysuite-aftersale-client-modal"
					>
						x
					</button>
				</header>
				<section 
					class="mobysuite-aftersale-client-modal-body"
					id="mobysuite-aftersale-client-modalDescription"
				>
					<slot
						name="body"
					>
						<b-overlay
							id="overlay-background"
							:show="getSavingState"
							:variant="variant"
							:opacity="opacity"
							:blur="blur"
							rounded="sm"
						>
							<b-row>
								<b-col
									sm="12"
									class="mb-4"
								>
									<b-icon icon="person"></b-icon> Crear un Usuario Secundario
								</b-col>
							</b-row>

							<b-row
								class="justify-content-md-center"
							>
								<b-col
									lg="6"
									sm="12"
									style="text-align: center;"
								>
									<b-row>
										<b-col
											sm="12"
											style="text-align: left;"
										>
											<p>Datos del usuario</p>
										</b-col>
										<b-col
											sm="12"
											class="mb-2"
										>
											<b-form-input
												type="text"
												v-model="name"
												placeholder="Ingrese Nombre completo: "
											>
											</b-form-input>
										</b-col>
										<b-col
											sm="12"
											class="mb-2"
										>
											<b-form-input
												type="text"
												v-model="lastName"
												placeholder="Ingrese Apellidos: "
											>
											</b-form-input>
										</b-col>
										<b-col
											sm="12"
											class="mb-2"
										>
											<b-form-input
												type="text"
												v-model="rut"
												placeholder="Ingrese Rut: "
											>
											</b-form-input>
										</b-col>
										<b-col
											sm="12"
											class="mb-2"
										>
											<b-input
												type="text"
												v-model="telephone"
												placeholder="Teléfono: "
											>
											</b-input>
										</b-col>
										<b-col
											sm="12"
											class="mb-2"
										>
											<b-form-input
												type="email"
												v-model="email"
												placeholder="Correo: "
											>
											</b-form-input>
										</b-col>
									</b-row>
								</b-col>
								<b-col
									lg="6"
									sm="12"
									style="text-align: center;"
								>
									<b-row>
										<b-col
											sm="12"
											style="text-align: left;"
										>
											<p>Designar Contraseña</p>
										</b-col>
										<b-col
											sm="12"
											class="mb-2"
										>
											<b-form-input
												type="password"
												v-model="password"
												placeholder="Escriba su contraseña: "
											>
											</b-form-input>
										</b-col>
										<b-col
											sm="12"
											class="mb-2"
										>
											<b-form-input
												type="password"
												v-model="password_confirmation"
												placeholder="Repetir contraseña: "
											>
											</b-form-input>
										</b-col>
									</b-row>
									
								</b-col>
							</b-row>
							<b-row
								class="justify-content-md-center text-right"
							>
								<b-col
									cols="12"
								>
									<button type="submit" class="mt-2 mb-3 btn-primary" @click="createSecondaryUser"><b-icon icon="download" aria-hidden="true" class="ml-2"></b-icon> Guardar</button>
								</b-col>
							</b-row>
						</b-overlay>
						<success-view
							v-if="getShowSuccessView"
							title="Éxito al guardar tus datos"
							buttonMessage="Volver al perfil"
							message="Tus datos han sido guardados correctamente."
							:modalShow="getShowSuccessView"
							successType = "profile"
						></success-view>
						<error-view
							v-if="getShowErrorView"
							title="Error al guardar tus datos"
							buttonMessage="Volver"
							message='Hubo un error al tratar de modificar tus datos de perfil. Por favor, intenta de nuevo más tarde.'
							:modalShow="getShowErrorView"
							errorType="profile"
						>
						</error-view>
					</slot>
				</section>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SuccessView from '@/components/SuccessView.vue'
import ErrorView from '@/components/ErrorView.vue'

export default {
    name: 'SecondaryUserModal',
	data() {
        return {
            name: '',
			lastName: '',
			rut: '',
			telephone: '',
			email: '',
			password: '',
			password_confirmation: '',
			variant: 'light',
            opacity: 0.85,
            blur: '2px',
        }
    },
	components: {
        SuccessView,
		ErrorView,
	},
	methods: {
		...mapActions('general', ['generateSecondaryUser']),
		createSecondaryUser() {
			let user =
				{
					email: this.email,
					name: this.name,
					lastname: this.lastName,
					rut: this.rut,
					phone: this.telephone,
					password: this.password,
					password_confirmation: this.password_confirmation
				}
			this.generateSecondaryUser(user)
		},
		close() {
			this.$emit('close')
		},
	},
    computed: {
      user(){
        return this.$auth.user()
      },
		...mapGetters('general', [
            'getSavingState',
            'getShowSuccessView',
			'getShowErrorView',
        ])
    }
}
</script>
